/* Styles go here */
.logofont{
font-family: 'Aclonica', sans-serif;
}

.generalfont{
  font-family: 'Carme', sans-serif;
  color: antiquewhite;
}

.sitetitle{
 text-align: center;
 font-family: 'Times New Roman', Times, serif;
 font-weight: bold;
 font-size: 6.4vw;
 color: rgb(0, 163, 232);
 text-shadow: 2px 2px 5px black;
}

.account{
    font-size: 19px;
  }

.siteHeading{
 text-align: center;
 font-family: 'Courier New', Courier, monospace;
 font-size:3.2vw;
 color: #ff0046
}

.errorMessage{
  text-align: center;
 /* font-family: 'Courier New', Courier, monospace; */
 font-size:2.4vw;
 color: #00fff2
}

.infoCard{
  position: relative;
  z-index: 2;
  border-top-style: solid;
  border-top-color: #ff0046;
  border-top-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ff0046;
  border-bottom-width: 2px;
}

.redline{
    border-top-style: solid;
    border-top-color: #ff0046;
    border-top-width: 2px;
     border-bottom-style: solid;
  border-bottom-color: #ff0046;
  border-bottom-width: 2px;
}

.sectionTitleRight{
 position: absolute;
 top: 0;
 right: 0;
 z-index: -1;
 font-family: 'Courier New', Courier, monospace;
 font-size:4.2vw;
 color: #ff0046
}
.sectionTitleLeft{
 position: absolute;
 top: 0;
 Left: 0;
 z-index: -1;
 font-family: 'Courier New', Courier, monospace;
 font-size:4.2vw;
 color: #ff0046
}


.sectionText{
 font-family: 'Arial', Arial, sans-serif;
 font-weight: lighter;
 font-size:larger;
 color: white
}

.largeBlue{
  text-align: center;
  font-size: 1.8vw;
  color: rgb(0, 163, 232);
}

.buttonText{
  font-family: 'Hi Melody', cursive;
  font-size: xx-large;
  color: white;
  background-color: rgb(0, 163, 232);
  border:rgb(0, 163, 232);
}

.buttonDisabled{
  font-family: 'Hi Melody', cursive;
  font-size: xx-large;
  color: rgb(117, 117, 117);
  background-color: rgb(255, 255, 255);
  border:rgb(192, 192, 192);
  border-style: solid;
  border-width: 1px;
}

.buttonText:hover{
  background-color:rgb(220, 53, 69);
  color: white;
}


.special{
    font-family: 'Hi Melody', cursive;
    color: rgb(220, 53, 69);
    font-size: 45px;
}

.specialMobile{
    font-family: 'Hi Melody', cursive;
    color: rgb(220, 53, 69);
    font-size: 25px;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Lemonada', cursive;
}

p {
  font-size: large;
}

.token {
  max-height: 69px;
  max-width: 420px;
}

.sitebackground {
  /* background-image: url('../GTC Full Logo.jpg'); */
  background-image: url('../logo3.png');
  background-color: rgb(0, 163, 232);
  background-repeat: repeat;
  background-size: 6vw;
  min-height: 800px;
  /* pr-4 pl-4 pb-5 */
  padding-left: 50px;
  padding-right: 50px;
}

.psmall {
  font-size: 3vw;
}

.backgroundoverlay{
  background-color: aliceblue;
  opacity: .55;
}

.goodnft{
  width: 100%;
  /* border: solid 1px rgb(180, 0, 126); */
  border: solid 1px #ff00448e;
  background-color: rgb(0, 0, 0, 0.3);
  padding: 15px;
}

.goodCreatureContainer{
  width: 100%;
  height: 69px
}

.goodCreature{
  position:absolute;
  height: 69px
}


.mintNFT {
  display: block;
  position: absolute; /* Position the background text */
  left: 50%;
  transform: translate(-50%);
  padding: 10px;
}


.faded
{
  opacity: 0.55
}

.moreFaded
{
  opacity: 0.15
}

@media(max-width: 900px){
  .account{
    font-size: 15px;
  }
  .sitebackground {
    background-size: 7.5vw;
    padding-left: 30px;
    padding-right: 30px;
  }
  .siteHeading{
    font-size:4.5vw;
  }
  .largeBlue{
    font-size: 2.6vw;
  }
  .goodCreatureContainer{
    width: 100%;
    height: 60px
  }

  .sectionText{
    font-size:large;
  }

  .goodCreature{
    position:absolute;
    height: 60px
  }
  .sectionTitleRight{
  font-size:6vw;
  }
  .sectionTitleLeft{
  font-size:6vw;
  }
}

@media(max-width: 540px){
  .sitetitle{
  font-size: 7vw;
  }
  .goodnft{
    padding: 10px;
  }
  .sectionTitleRight{
  font-size:7vw;
  }
  .sectionTitleLeft{
  font-size:7vw;
  }
  .sectionText{
    font-size:medium;
  }
  .account{
    font-size: 11px;
  }
  
  .siteHeading{
    font-size:6vw;
  }

  .errorMessage{
  font-size:4vw;
  }

  .sitebackground {
    background-size: 9vw;
    min-height: 600px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .largeBlue{
    font-size: 4vw;
  }
  .goodCreatureContainer{
    width: 100%;
    height: 50px
  }

  .goodCreature{
    position:absolute;
    height: 50px
  }
}